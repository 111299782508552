
import { defineComponent } from 'vue';
import Panel from '@/components/layout/Panel.vue';
import ViewMixin from '@/mixins/ViewMixin';
import TextField from '@/components/controls/TextField.vue';
import Button from '@/components/controls/Button';
import * as companyService from '@/services/social/companyService';
import { Company } from '@/interfaces/social/company';
import LoadingIndicatorBeam from '@/components/loading/LoadingIndicatorBeam.vue';
import CompanyListItem from '@/components/social/company/CompanyListItem.vue';
import { redirectToDiscord } from '@/helpers/index';
import { JwtUser } from '@/interfaces/identity/user';

interface Data {
    searchTerm: string;
    isLoading: boolean;
    companies: Company[];
}

export default defineComponent({
    name: 'Companys',
    components: {
        Button,
        Panel,
        TextField,
        LoadingIndicatorBeam,
        CompanyListItem,
    },
    mixins: [ViewMixin],
    data: (): Data => ({
        searchTerm: '',
        isLoading: false,
        companies: [],
    }),
    computed: {
        user(): JwtUser | null {
            return this.$store.getters['authentication/user'];
        },
    },
    methods: {
        redirectToDiscord,
        async refreshData(): Promise<void> {
            await this.loadCompanys();
        },
        async loadCompanys(): Promise<void> {
            this.isLoading = true;
            try {
                const response = await companyService.getMultiple({
                    pageSize: -1,
                    searchQuery: this.searchTerm,
                });
                this.companies = response.data;
            } catch (_) {
                // do nothing
            }
            this.isLoading = false;
        },
    },
    created(): void {
        this.setPageTitle([this.$t('companies'), this.$t('social')]);
        this.refreshData();
    },
});
