
import { defineComponent } from 'vue';
import { Company } from '@/interfaces/social/company';

export default defineComponent({
    name: 'CompanyListItem',
    props: {
        company: {
            type: Object,
            required: true,
        },
    },
    computed: {
        typedCompany(): Company {
            return this.company as Company;
        },
        logoStyle(): Record<string, string> {
            return {
                backgroundImage: this.typedCompany && this.typedCompany.logoUri ? `url('${this.typedCompany.logoUri}')` : '',
            };
        },
    },
});
